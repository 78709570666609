<template>
	<div class="student-place-qcm-container">
		<FullPageLoader v-if="isLoading"/>
		<h1>{{ qcm.title }}</h1>
		<div class="remaining-time-container">
			<p class="timer">Temps restant <br> {{ timeLeft }}</p>
		</div>
		<div class="questions-container">
			<div class="question-container" v-for="question in qcm.questions" :key="question.id">
				<h2><span v-html="question.question"> </span> <span>({{question.point }} points)</span></h2>
				<QCMImage v-if="question.image" :image="question.image" />

				<div class="answers-container">
					<div class="answer" v-for="answer in question.answers_without_correct_answer" :key="answer.id">
						<label :for="answer.id">
							<input @change="checkIfThereIsTimeLeft()" v-model="selectedAnswers" :id="answer.id" type="checkbox" :value="getValue(question.id,answer.id)">
							<span v-html="answer.answer"></span>
						</label>
					</div>
				</div>
			</div>
		</div>
		<button class="submit dark" @click="submit()">Valider</button>
		<Teleport to="body">
			<PopUpShowImg :img="selectedQuestion.displayedImg" v-if="isPopUpImgVisible" @close="isPopUpImgVisible = false"/>
		</Teleport>
	</div>
</template>

<script>
import axios from 'axios'
import Config from '../../config'
import {  authenticatedStudentApiFetch } from '../../utils'
import FullPageLoader from '../Utils/FullPageLoader.vue'
import PopUpShowImg from '../PopUp/QCM/PopUpShowImg.vue'
import QCMImage from '../Admin/Resources/Resource/QCM/QCMImage.vue'
export default {
	components: { FullPageLoader, PopUpShowImg, QCMImage },
	data() {
		return {
			qcm:{},
			questions:[],
			answers:[],
			selectedAnswers: [],
			timeLeft: '10:00',
			isLoading: false,
			url: `${Config.backend_url}`,
			qcmStudentStartedAt: {},
			interval: null,
			alreadyDone: false,
			isPopUpImgVisible: false,
			selectedQuestion: null,
		}
	},

	methods: {
		showImg(selectedQuestion){
			this.selectedQuestion = selectedQuestion
			this.isPopUpImgVisible = true
		},
		async checkIfThereIsTimeLeft(){
			if(this.timeLeft === '0:0'){
				this.$store.state.toast = {
					status: 'error',
					text: 'Le temps est écoulé',
				}
				await this.submit()
				this.$router.push({name: 'StudentPlaceEvaluation'})
			}
		},
		getValue(questionId, answerId){
			return {questionId, answerId}
		},
		async submit(){
			
			this.isLoading = true
			this.checkIfAlreadyDoneQcm()
			for(const question of this.qcm.questions){
				const answersForQuestion = this.selectedAnswers.filter(x => x.questionId === question.id)
				const answers = answersForQuestion.map((answer)=>{
					return answer.answerId
				})
				const data = {
					qcm: this.qcm.id,
					question: question.id,
					answers: answers
				}

				try{
					await authenticatedStudentApiFetch(`/qcm-student-answers/`, {
						method: 'POST',
						body: JSON.stringify(data),
					})
				}
				catch(e){
					console.log(e)
				}
				
			}

			await this.calculateNote()
			this.isLoading = false
				

			this.$store.state.toast = {
				status: 'success',
				text: 'QCM validé',
			}

			this.$router.push({name: 'StudentPlaceEvaluation'})
		},

		async calculateNote(){
			const data = {
				qcm: this.qcm.id	
			}
			await axios.post(`${this.url}/qcm-student-notes-calculator`, data, {headers: {Authorization: `Student Bearer ${localStorage.getItem('studentToken')}`}})	
		},

		async getImg(question){
			const url = `${Config.backend_url}${question.image}`
			const res = await axios.get(url, {responseType: 'blob'})
			.then(blob => blob.data)

			return URL.createObjectURL(res)
		},

		async getQcm() {
			this.qcm = await authenticatedStudentApiFetch( `/qcm-students/${this.$route.params.id}/`, {method: 'GET'})
				.then(res =>res.json())
			
			for(const question of this.qcm.questions){
				if(question.image){
					question.displayedImg = await this.getImg(question)
				}
			}

			const res = await authenticatedStudentApiFetch(`/qcm-student-started-at/?qcm=${this.$route.params.id}`, {method: 'GET'})
				.then(res => res.json())

			this.qcmStudentStartedAt = res[0]

			if(!this.qcmStudentStartedAt){
				const data = {
					qcm: this.qcm.id
				}
				const res  = await authenticatedStudentApiFetch(`/qcm-student-started-at/`, {
					method: 'POST',
					body: JSON.stringify(data)
				})
				.then(res => res.json())
				this.qcmStudentStartedAt = res
			}
		},
		addMinutes(date, minutes) {
			const dateCopy = new Date(date);
			dateCopy.setMinutes(date.getMinutes() + minutes);  
			return dateCopy;
		},
		
		startTimer(){
			const startedAt = new Date(this.qcmStudentStartedAt.started_at)
			
			const endAt =  this.addMinutes(startedAt, this.qcm.duration)
			this.interval = setInterval(() => {
				const currentDate = new Date()
				const diff = endAt - currentDate
				const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
				const seconds = Math.floor((diff % (1000 * 60)) / 1000);
				if(seconds < 10){
					this.timeLeft = `${minutes}:0${seconds}`
				}
				else{
					this.timeLeft = `${minutes}:${seconds}`
				}

				if(diff < 0){
					clearInterval(this.interval)
					this.timeLeft = '0:0'
					
					this.submit()
				}
			}, 1000)

		},
		async checkIfAlreadyDoneQcm(){
			const res = await authenticatedStudentApiFetch(`/qcm-student-answers/?qcm=${this.$route.params.id}`, {method: 'GET'})
				.then(res => res.json())
				
			if(res.results.length > 0){
				this.alreadyDone = true
				this.$store.state.toast = {
					status: 'error',
					text: 'Vous avez déjà répondu à ce QCM',
				}
				this.$router.push({name: 'StudentPlaceEvaluation'})
			}
		},
	},
	
	async created() {
		this.isLoading = true
		await this.checkIfAlreadyDoneQcm()
		if(this.alreadyDone) return
		await this.getQcm()
		this.startTimer()
		
		this.isLoading = false
	},
	beforeUnmount() {
		clearInterval(this.interval)
	}	
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/student-place/qcm.scss';
</style>
